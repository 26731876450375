<template>
  <q-btn
    @click="showDialog()"
    outline
    color="white"
    class="q-mb-lg bg-red full-width default-rounded"
    label="Upload NF"
  />
  <q-dialog v-model="dialog" persistent>
    <q-card style="width: 700px; max-width: 80vw" class="q-pa-md">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6 text-uppercase text-grey-8">Upload NF</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <div class="q-ma-md">
        <q-form
          @submit.prevent="uploadNF()"
          greedy
          enctype="multipart/form-data"
        >
          <q-input
            type="textarea"
            filled
            v-model="observation"
            label="Observação"
            class="q-mb-lg"
            :rules="[(val) => val.length > 0 || 'Campo obrigatório']"
            lazy-rules="ondemand"
          />

          <q-file
            counter
            filled
            clearable
            bottom-slots
            v-model="file"
            accept=".pdf, pdf/*"
            label="Selecione um arquivo para realizar o upload"
            :rules="[(val) => val || 'Campo obrigatório']"
            lazy-rules="ondemand"
            :max-file-size="716800"
            @rejected="maxFileSize()"
          >
            <template v-slot:prepend>
              <q-icon name="cloud_upload" />
            </template>
          </q-file>
          <q-card-actions align="center" class="q-mt-md">
            <q-btn
              width="200px"
              icon="cloud_upload"
              color="black"
              type="submit"
              :label="$t('Fazer Upload')"
              :loading="onUploading"
            />
          </q-card-actions>
        </q-form>
        <q-linear-progress
          v-if="onUploading"
          :indeterminate="onUploading"
          style="font-size: 20pt"
          color="blue"
        />
      </div>
    </q-card>
  </q-dialog>
</template>
<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { baseUrlCC } from "@/shared/http/api";
import { useI18n } from "vue-i18n";
import { Notify } from "quasar";
import { ref } from "vue";
import axios from "axios";

export default {
  name: "UploadDoc",

  props: {
    closureId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const dialog = ref(false),
      onUploading = ref(false),
      file = ref(null),
      observation = ref(""),
      uploadUrl = `${baseUrlCC}/api/v3/closure/${props.closureId}/doc/upload`,
      commissionNamespace = createNamespacedHelpers("commission"),
      authNamespace = createNamespacedHelpers("auth"),
      { setClosedDocUploaded } = commissionNamespace.useActions([
        "setClosedDocUploaded",
      ]),
      { token } = authNamespace.useState(["token"]);

    const showDialog = () => {
        dialog.value = !dialog.value;
      },
      onUploadingSuccess = () => {
        setClosedDocUploaded().then(() => {
          Notify.create({
            message: t("notify.success"),
            type: "positive",
            color: "light-blue-8",
            position: "top-right",
          });
          dialog.value = !dialog.value;
        });
      };

    const getData = () => {
        const formData = new FormData();
        formData.append("file", file.value);
        formData.append("observation", observation.value);
        formData.append("mark_doc_rec", false);
        return formData;
      },
      uploadNF = () => {
        onUploading.value = !onUploading.value;
        axios
          .post(uploadUrl, getData(), {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          })
          .then(() => {
            onUploadingSuccess();
            onUploading.value = !onUploading.value;
          })
          .catch(() => {
            Notify.create({
              message: t("commission.upload_nf_fail"),
              type: "negative",
              color: "light-red-8",
              position: "top-right",
            });
            onUploading.value = !onUploading.value;
          });
      },
      maxFileSize = () => {
        Notify.create({
          message: t("commission.max_file_size"),
          type: "negative",
          color: "red-8",
          position: "bottom",
        });
      };

    return {
      file,
      token,
      dialog,
      uploadUrl,
      observation,
      uploadNF,
      onUploading,
      maxFileSize,
      showDialog,
      onUploadingSuccess,
    };
  },
};
</script>

<style lang="css">
.q-uploader__header {
  background-color: #222222;
}
</style>
