<template>
  <div class="commission bg-white default-rounded">
    <div
      class="commission-item no-wrap bg-off text-grey-7  row justify-between"
    >
      <div class="icon row justify-center flex-center">
        <q-icon size="2.5em" name="paid" class="q-pl-lg" />
      </div>
      <div
        class="commission-item-info q-ma-lg width-100 row flex-center justify-between"
      >
        Nenhuma movimentação encontrada
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MovementItemEmpty",
};
</script>

<style scoped>
.commission {
  font-size: 1.1em;
}
.mobile .commission-item-info > div {
  width: 100%;
}
.bg-off {
  background-color: #ffffff9c !important;
}
</style>
