<template>
  <div class="qs-mb-lg qs-mr-lg paid-total  column flex-center justify-between">
    <span
      class="text-bold text-grey-5"
      :style="skeleton ? { color: '#d4d3de' } : {}"
    >
      {{ label }}
    </span>
    <div class="text-weight-bold row no-wrap money q-pa-sm flex-center">
      <q-skeleton
        v-if="skeleton"
        animation="pulse-x"
        height="1.13em"
        type="QBadge"
      />
      <span v-if="!skeleton">
        {{ $t("global.moneyIcon") }}
        {{ toMoney(value || 0) }}
      </span>
    </div>
  </div>
</template>

<script>
import { toMoney } from "@/shared/helpers/numbersManipulation";

export default {
  name: "paidTotalItem",

  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    skeleton: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return { toMoney: toMoney };
  },
};
</script>

<style scoped>
.money {
  font-size: 1.8em;
  line-height: 1;
}

.paid-total {
  width: 100%;
  padding: 5px 10px;
}

.mobile .paid-total {
  box-shadow: none !important;
}
</style>
