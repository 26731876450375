<template>
  <q-skeleton
    v-if="skeleton"
    type="text"
    class="text-subtitle2 bg-grey-6"
    width="10em"
    animation="fade"
  />
  <h6 v-if="!skeleton" class="subtitle">
    <span class="row justify-between">
      <span class="text-grey-7 text-weight-medium">{{ title }}</span>
      <slot></slot>
    </span>
  </h6>
</template>

<script>
export default {
  name: "page-subtitle",

  props: {
    skeleton: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  font-size: 1.1em;
  margin: 0;
  padding-left: 10px;
  text-transform: capitalize;
}
</style>
