<template>
  <div class="text-grey-7">
    <div class="row q-mt-lg q-mb-lg sale-item-details justify-between q-mt-sm">
      <div class="col-6">
        {{ $t("commission.order_value") }}
      </div>
      <div class="col6 text-weight-bold">
        <div class="row no-wrap justify-end">
          <span class="q-mr-lg">{{ $t("global.moneyTypeLabel") }}</span>
          <q-skeleton animation="pulse-x" height="1.13em" type="QBadge" />
        </div>
      </div>
    </div>
    <div class="row q-mb-lg justify-between q-mt-sm">
      <div class="col-6">
        {{ $t("global.tax") }}
      </div>
      <div class="col-6 text-weight-bold">
        <div class="row no-wrap justify-end">
          <span class="q-mr-lg">{{ $t("global.moneyTypeLabel") }}</span>
          <q-skeleton animation="pulse-x" height="1.13em" type="QBadge" />
        </div>
      </div>
    </div>
    <div class="row q-mb-lg justify-between q-mt-sm">
      <div class="col-6">
        {{ $t("commission.amount_to_receiver") }}
      </div>
      <div class="col-6 text-weight-bold">
        <div class="row no-wrap justify-end">
          <span class="q-mr-lg">{{ $t("global.moneyTypeLabel") }}</span>
          <q-skeleton animation="pulse-x" height="1.13em" type="QBadge" />
        </div>
      </div>
    </div>
    <div class="q-mt-lg">
      <q-skeleton
        width="100%"
        animation="QToolbar"
        height="2em"
        type="QBadge"
      />
    </div>

    <q-separator class="q-ma-lg" inset="instructions" />
    <div class="q-mt-lg">
      <q-skeleton
        width="100%"
        animation="QToolbar"
        height="2em"
        type="QBadge"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SummarySkeleton",
};
</script>
