<template>
  <div class="row wrap-reverse">
    <div class="width-fit-content-flex">
      <page-subtitle
        :skeleton="in_request"
        :tooltip="$t('commission.helper')"
        :title="$t('commission.drives')"
      />
      <pagination
        :resource="pagination"
        :repository="fetchOpenedAction"
        :disabled="in_request"
      />
      <div class="column q-mt-lg" v-if="!in_request">
        <movement-item-empty v-if="movements.length === 0" />
        <movement-item
          v-else
          v-for="movement in movements"
          :order-id="movement['order_id']"
          :order-external-id="movement['order_external_id']"
          :created-at="movement['created_at']"
          :to-receiver-value="movement['value']"
          :total-sale-value="movement['order_value']"
          :reward-id="movement['reward_id']"
          :key="`movement-id-${movement['id']}`"
        />
      </div>
      <div v-else class="column q-mt-lg">
        <movement-item
          v-for="movement in 5"
          :skeleton="true"
          :key="`movement-skeleton-${movement}`"
        />
      </div>
    </div>

    <div
      :style="$q.platform.is.desktop ? 'min-width: 300px' : ''"
      :class="{
        'q-ml-lg col-3 ': $q.platform.is.desktop,
        'full-width': $q.platform.is.mobile,
      }"
    >
      <div
        class="bg-white q-pa-lg"
        :class="{ 'default-shadow default-rounded': $q.platform.is.desktop }"
      >
        <paid-total-item
          :skeleton="in_request"
          class="bg-white q-mb-lg q-mt-lg default-rounded"
          :label="$t('commission.amount_to_receiver')"
          :value="summary['value']"
        />
        <q-separator />
        <summary-opened-info v-if="!in_request" :summary="summary" />
        <summary-skeleton v-if="in_request" />
      </div>
    </div>
  </div>
</template>

<script>
import SummaryOpenedInfo from "@/modules/main/pages/commission/components/SummaryOpenedInfo";
import SummarySkeleton from "@/modules/main/pages/commission/components/SummarySkeleton";
import MovementItemEmpty from "@/modules/main/pages/commission/components/movementItemEmpty";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import PageSubtitle from "@/modules/main/components/page-subtitle";
import Pagination from "@/modules/main/components/pagination";
import PaidTotalItem from "./paidTotalItem";
import movementItem from "./movementItem";
import { ref } from "vue";

const { useActions, useGetters } = createNamespacedHelpers("commission");

export default {
  components: {
    MovementItemEmpty,
    SummarySkeleton,
    Pagination,
    PageSubtitle,
    SummaryOpenedInfo,
    PaidTotalItem,
    movementItem,
  },
  setup() {
    const showBarInfo = ref(null),
      { fetchOpenedAction } = useActions(["fetchOpenedAction"]),
      { in_request, summary, movements, pagination } = useGetters({
        in_request: "getOpenedInRequest",
        summary: "getOpenedSummary",
        movements: "getOpenedMovements",
        pagination: "getOpenedPaginationResource",
      });

    fetchOpenedAction();

    return {
      showBarInfo,
      in_request,
      fetchOpenedAction,
      summary,
      movements,
      pagination,
      handleSwipe() {
        showBarInfo.value = true;
      },
      handleSwipeDown() {
        showBarInfo.value = false;
      },
    };
  },
};
</script>
