<template>
  <div class="commission bg-white q-mb-sm default-rounded">
    <div class="commission-item no-wrap text-grey-7 row justify-between">
      <div class="icon row justify-center flex-center">
        <div class="q-pl-lg" v-if="skeleton">
          <q-skeleton type="circle" />
        </div>
        <q-icon v-if="!skeleton" size="2.5em" name="paid" class="q-pl-lg" />
      </div>
      <div
        class="
          commission-item-info
          q-ma-lg
          width-100
          row
          flex-center
          justify-between
        "
      >
        <div class="order-details-item mobile-inline">
          <div>
            <q-skeleton
              width="7em"
              v-if="skeleton"
              type="text"
              animation="fade"
            />

            <div v-if="!skeleton">
              <span
                class="text-weight-bold mobile-medium text-uppercase"
                style="font-size: 10pt;"
              >
                {{ $t("commission.protocol") }}:
              </span>
              #{{ rewardId }}
            </div>
          </div>
          <div>
            <q-skeleton
              v-if="skeleton"
              width="3em"
              type="text"
              animation="fade"
            />
            <small v-if="!skeleton">{{ dateFormatted }}</small>
          </div>
        </div>
        <div class="order-details-item size-small-mobile">
          <q-skeleton
            v-if="skeleton"
            type="text"
            class="text-subtitle2"
            animation="fade"
          />
          <q-skeleton
            v-if="skeleton"
            type="text"
            class="text-subtitle2"
            animation="fade"
          />
          <div v-if="!skeleton" class="text-weight-medium row justify-between">
            <span class="q-mr-sm">{{ $t("commission.to_receiver") }}:</span>
            <span>{{ toMoneyLabel(toReceiverValue) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import moment from "moment";

export default {
  name: "MovementItem",

  props: {
    orderId: {
      type: String,
    },
    orderExternalId: {
      type: String,
    },
    rewardId: {
      type: String,
    },
    totalSaleValue: {
      type: String,
    },
    toReceiverValue: {
      type: String,
    },
    createdAt: {
      type: String,
    },
    createdAtFormat: {
      type: String,
      default: null,
    },
    skeleton: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    return {
      toMoneyLabel: toMoneyLabel,
      dateFormatted: moment(props.createdAt, props.createdAtFormat).format(
        "LL"
      ),
    };
  },
};
</script>

<style scoped>
.desktop .order-details-item {
  min-width: 130px;
}
.commission {
  font-size: 1.1em;
}
.mobile .commission-item-info > div {
  width: 100%;
}
.mobile .mobile-inline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.mobile .mobile-medium {
  font-weight: 500;
}
.mobile .size-small-mobile {
  font-size: 0.8em;
}
.bg-off {
  background-color: #ffffff9c !important;
}
</style>
