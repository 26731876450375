<template>
  <div class="text-grey-7">
    <div class="row q-mt-lg q-mb-lg sale-item-details justify-between q-mt-sm">
      <div class="col-6">
        {{ $t("commission.order_value") }}
      </div>
      <div class="col6 text-weight-bold">
        <div class="row no-wrap justify-end">
          <span class="q-mr-lg">{{ $t("global.moneyTypeLabel") }}</span>
          <span>{{ toMoney(summary.order_sum || 0) }}</span>
        </div>
      </div>
    </div>
    <div class="row q-mb-lg justify-between q-mt-sm">
      <div class="col-6">
        {{ $t("commission.amount_to_receiver") }}
      </div>
      <div class="col-6 text-weight-bold">
        <div class="row no-wrap justify-end">
          <span class="q-mr-lg">{{ $t("global.moneyTypeLabel") }}</span>
          <span>{{ toMoney(summary.value || 0) }}</span>
        </div>
      </div>
    </div>
    <div class="q-pt-lg" v-if="hasMinValue">
      <p
        class="text-weight-thin qs-mt-lg"
        v-html="$t(minValueLabel, { label: minValueBold })"
      />
    </div>
    <!--
    <q-separator inset="instructions" />
    <div
      id="instructions"
      class="q-mt-lg"
      v-html="summary.instructions_open_commissions"
    />
    -->
  </div>
</template>
<script>
import { toMoneyLabel, toMoney } from "@/shared/helpers/numbersManipulation";
import moment from "moment";

export default {
  name: "SummaryOpenedInfo",

  props: {
    summary: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const minValue = parseFloat(props.summary.min_value || 0),
      valueReached = parseFloat(props.summary.value || 0);

    return {
      hasMinValue: minValue > 0,
      toMoneyLabel: toMoneyLabel,
      toMoney: toMoney,
      dateFormatted: moment(props.summary.due_date).format("LL"),
      minValueLabel:
        valueReached > minValue
          ? "commission.minimum_value_reached"
          : "commission.minimum_value_no_reached",
      minValueBold: `<strong>${toMoneyLabel(minValue)}</strong>`,
    };
  },
};
</script>
