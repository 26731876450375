<template>
  <div class="row wrap-reverse">
    <div class="width-fit-content-flex">
      <page-subtitle
        :skeleton="in_request"
        :tooltip="$t('commission.helper')"
        :title="$t('commission.drives')"
      />

      <div class="column q-mt-lg" v-if="!in_request">
        <movement-item-empty v-if="movements.length === 0" />
        <movement-item
          v-else
          v-for="movement in movements"
          class="q-mb-lg"
          :order-id="movement['order_id']"
          :order-external-id="movement['order_external_id']"
          :created-at="movement['created_at']"
          :to-receiver-value="movement['value']"
          :total-sale-value="movement['order_value']"
          :reward-id="movement['reward_id']"
          :key="`movement-id-${movement['id']}`"
        />
      </div>
      <div v-else class="column q-mt-lg">
        <movement-item
          v-for="movement in 5"
          :skeleton="true"
          :key="`movement-skeleton-${movement}`"
        />
      </div>
    </div>
    <div
      :style="$q.platform.is.desktop ? 'min-width: 300px' : ''"
      :class="{
        'q-ml-lg col-3 ': $q.platform.is.desktop,
        'full-width': $q.platform.is.mobile,
      }"
    >
      <div
        class="bg-white q-pa-lg default-rounded"
        :class="{ 'default-shadow default-rounded': $q.platform.is.desktop }"
      >
        <paid-total-item
          class="bg-white q-mb-lg default-rounded"
          :skeleton="in_request"
          :label="$t('commission.amount_to_received')"
          :value="closure['total_liquido']"
        />
        <q-separator />
        <summary-closed-info
          v-if="!in_request"
          :show-link-rpa="params['show_link_rpa']"
          :summary="summary"
          :closure="closure"
        />

        <summary-skeleton v-else />
        <div
          v-if="checkPdfLink(pdfLink) && partner.pf_pj.toLowerCase() === 'pj'"
          id="pdfLinkInstructions"
          class="q-pa-md"
        >
          <a
            :href="checkPdfLink(pdfLink)"
            :download="$t('commission.nf_instructions_file_name')"
            target="_blank"
            >{{ $t("commission.nf_instructions") }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SummaryClosedInfo from "@/modules/main/pages/commission/components/SummaryClosedInfo";
import MovementItemEmpty from "@/modules/main/pages/commission/components/movementItemEmpty";
import SummarySkeleton from "@/modules/main/pages/commission/components/SummarySkeleton";
import PageSubtitle from "@/modules/main/components/page-subtitle";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import PaidTotalItem from "./paidTotalItem";
import movementItem from "./movementItem";
import { ref, onMounted } from "vue";

const partnerNamespace = createNamespacedHelpers("partner");
const { useActions, useGetters } = createNamespacedHelpers("commission");

export default {
  name: "CommissionClosedTab",

  components: {
    MovementItemEmpty,
    SummarySkeleton,
    SummaryClosedInfo,
    PageSubtitle,
    PaidTotalItem,
    movementItem,
  },

  setup() {
    const { in_request, summary, movements, params, closure } = useGetters({
        in_request: "getClosedInRequest",
        summary: "getClosedSummary",
        params: "getClosedParams",
        closure: "getClosedClosure",
        movements: "getClosedMovements",
      }),
      { fetchClosedAction } = useActions(["fetchClosedAction"]),
      showBarInfo = ref(null);

    fetchClosedAction();

    const { pdfLink } = useGetters({
        pdfLink: "getPdfLink",
      }),
      { fetchParametersByKeys } = useActions(["fetchParametersByKeys"]);
    const { partner } = partnerNamespace.useGetters({
      partner: "getPartner",
    });

    onMounted(async () => {
      await fetchParametersByKeys({ keys: ["document_pdf_instructions"] });
    });
    const checkPdfLink = (pdf) => {
      if (pdf.document_pdf_instructions) {
        return pdf.document_pdf_instructions.value;
      }
    };


    return {
      partner,
      checkPdfLink,
      pdfLink,
      showBarInfo,
      in_request,
      closure,
      params,
      fetchClosedAction,
      summary,
      movements,
      handleSwipe() {
        showBarInfo.value = true;
      },
      handleSwipeDown() {
        showBarInfo.value = false;
      },
    };
  },
};
</script>
