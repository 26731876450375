<template>
  <div class="text-grey-7">
    <div class="row q-mt-lg q-mb-lg sale-item-details justify-between q-mt-sm">
      <div class="col-6">
        {{ $t("commission.gross_value") }}
      </div>
      <div class="col6 text-weight-bold">
        <div class="row no-wrap justify-end">
          <span class="q-mr-xs">{{ $t("global.moneyTypeLabel") }}</span>
          <span>{{ toMoney(closure.total_bruto || 0) }}</span>
        </div>
      </div>
    </div>
    <div class="row q-mb-lg justify-between q-mt-sm">
      <div class="col-6">
        {{ $t("global.tax") }}
      </div>
      <div class="col-6 text-weight-bold">
        <div class="row no-wrap justify-end">
          <span class="q-mr-xs">{{ $t("global.moneyTypeLabel") }}</span>
          <span>{{ toMoney(taxValue || 0) }}</span>
        </div>
      </div>
    </div>
    <div class="row q-mb-lg justify-between q-mt-sm">
      <div class="col-6">
        {{ $t("global.otherCosts") }}
      </div>
      <div class="col-6 text-weight-bold">
        <div class="row no-wrap justify-end">
          <span class="q-mr-xs">{{ $t("global.moneyTypeLabel") }}</span>
          <span>{{ toMoney(otherTax || 0) }}</span>
        </div>
      </div>
    </div>
    <div class="row q-mb-lg justify-between q-mt-sm">
      <div class="col-6">
        {{ $t("commission.value_bonus") }}
      </div>
      <div class="col-6 text-weight-bold">
        <div class="row no-wrap justify-end">
          <span class="q-mr-xs">{{ $t("global.moneyTypeLabel") }}</span>
          <span>{{ toMoney(closure.total_liquido || 0) }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="closure.liquidated_at"
      class="row q-mb-lg justify-between q-mt-sm"
    >
      <div class="col-6">
        {{ $t("commission.pay_at") }}
      </div>
      <div class="col-6 text-weight-bold">
        <div class="row no-wrap justify-end">
          <span>{{ moment(closure.liquidated_at).format("DD/MM/YYYY") }}</span>
        </div>
      </div>
    </div>
    <div v-else class="row q-mb-lg justify-between q-mt-sm">
      <div class="col-6">
        {{ $t("commission.released_at") }}
      </div>
      <div class="col-6 text-weight-bold">
        <div class="row no-wrap justify-end">
          <span>{{
            moment(closure.period.due_date).format("DD/MM/YYYY")
          }}</span>
        </div>
      </div>
    </div>
    <a
      v-if="showLinkRpa"
      style="text-decoration: none"
      :href="
        `http://testeapi.flip.net.br/cc/api/v3/reports/partner/rpa?closure_id=${closure.id}`
      "
    >
      <q-btn
        @click="dialog = true"
        outline
        color="white"
        class="q-mb-lg bg-dark full-width default-rounded"
        label="Visualizar Rpa"
      />
    </a>
    <upload-doc class="q-mb-lg" :closure-id="closure.id" />
    <q-separator inset="instructions" />
    <div
      id="instructions"
      class="q-mt-lg"
      v-html="closure.instructions_open_commissions"
    ></div>
  </div>
</template>

<script>
import { toMoneyLabel, toMoney } from "@/shared/helpers/numbersManipulation";
import UploadDoc from "@/modules/main/pages/commission/components/uploadDoc";
import moment from "moment";

export default {
  name: "SummaryClosedInfo",

  components: { UploadDoc },

  props: {
    showLinkRpa: {
      type: Boolean,
      default: false,
    },
    closure: {
      type: Object,
      required: true,
    },
    summary: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const taxValue =
        parseFloat(props.closure.total_inss) +
        parseFloat(props.closure.total_iss) +
        parseInt(props.closure.total_irpf),
      otherTax = parseFloat(props.closure.total_tax),
      verifyRequireDoc = (value) => {
        const require_doc = JSON.parse(value);
        return require_doc.require_doc ? true : false;
      };

    return {
      verifyRequireDoc,
      taxValue,
      toMoneyLabel: toMoneyLabel,
      toMoney: toMoney,
      moment: moment,
      otherTax: otherTax,
    };
  },
};
</script>
