<template>
  <div
    v-if="unfilledDocs && unfilledDocs['0']"
    class="bg-red q-pa-lg default-rounded"
  >
    <p class="text-white text-weight-light">
      {{ $t("commission.unfilledTitle") }}
    </p>
    <ul class="q-list">
      <li
        v-for="(doc, key) in unfilledDocs"
        :key="key"
        class="text-white text-weight-light"
      >
        <span class="q-pl-lg q-pr-xs">-</span>
        {{ $t(`global.${doc.required_field}`) }}
      </li>
    </ul>
    <q-item
      class="text-white text-underline"
      :to="{
        name:
          unfilledDocs['0'].required_field === 'nome'
            ? 'account.personal-info'
            : 'account.bank-account',
      }"
    >
      {{ $t("commission.unfilledDsc", { date: dateFormatted }) }}
    </q-item>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { onMounted } from "vue";
import moment from "moment";

const { useState, useGetters, useActions } = createNamespacedHelpers(
  "commission"
);

export default {
  name: "DocsRequired",

  setup() {
    const { unfilledDocs } = useState(["unfilledDocs"]),
      { dueDate } = useGetters({ dueDate: "getClosedDate" }),
      { fetchDocsUnfilled } = useActions(["fetchDocsUnfilled"]);

    const dateFormatted = moment(dueDate).format("DD/MM/YYYY");

    onMounted(() => {
      fetchDocsUnfilled();
    });

    return {
      dueDate,
      dateFormatted,
      unfilledDocs,
    };
  },
};
</script>

<style scoped>
.q-list {
  list-style: none;
  padding: 0;
}
</style>
